.mapContainer {
  height: 100vh;
  width: 100%;
}

.viewMap {
  width: 100%;
}

.locationButton,
.searchHereButton {
  padding: 4px 3px;
  height: 29px;
  background-color: white;
  outline-style: none;
  border: none;
  border: 1px solid darkgray;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgb(0 0 0 / 65%);
  cursor: pointer;
}

.searchHereButton {
  left: -50vw;
  position: absolute;
  width: max-content;
  background-color: #184a87;
  color: white;
  padding: 0 20px;
  font-size: 16px;
  min-width: 189px;
}

.locationButton:hover {
  background-color: #f4f4f4;;
}

@media (max-width: 700px) {
  .viewMap {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .mapContainer {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .searchHereButton {
    left: -71vw;
  }
}