.shareButtonMobile,
.actionButtonDialog {
  display: none;
}

.lowerpriceView {
  background-color: #dcba0c;
  border-radius: 5px;
}

.lowerpriceBadge {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.addressText {
  display: block;
}

@media (max-width: 700px) {
  .shareButtonMobile {
    display: block;
    padding-top: 4px;
  }

  .shareButtonDesktop {
    display: none;
  }

  .lowerpriceView {
    width: 74px;
  }

  .lowerpriceBadge {
    display: block;
    text-align: center;
    font-size: 13px;
  }

  .mobileDialogView {
    top: 50px;
  }

  .heading,
  .stationDistanceText,
  .addressText {
    font-size: 15px !important;
  }

}