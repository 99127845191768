.customMarker {
  display: flex;
}

.customMarkerImg,
.customMarkerLowerImg,
.customMarkerLowerImgOpened,
.customMarkerImgOpened {
  position: absolute;
  top: -29px;
  left: -7px;
}

.customMarkerLowerImg {
  animation: bound-anim-img 1s 0.0s infinite;
}

.customMarkerImgOpened,
.customMarkerPriceOpened {
  animation: scale-anim 0.3s ease forwards;
}

.lowerPriceImg {
  position: absolute;
  top: -60px;
  left: 50px;
  animation: bound-anim-lower-img 1s 0.0s infinite;
}

.customUserMarkerImg {
  position: absolute;
}

.customPathMarkerImg {
  position: absolute;
  left: -3px;
  top: -25px;
}

.customMarkerPrice,
.customMarkerLowerPrice,
.customMarkerLowerPriceOpened,
.customMarkerPriceOpened {
  font-family: 'Pragati Narrow', sans-serif;
  position: absolute;
  top: -31px;
  width: max-content;
  left: 17px;
  color: white;
  font-size: 23px;
}

.customMarkerLowerPrice {
  color: #ffe500 !important;
  animation: bound-anim-txt 1s 0.0s infinite;
}

.customMarkerLowerPriceOpened,
.customMarkerLowerImgOpened {
  color: #ffe500 !important;
  animation: scale-anim 0.3s ease forwards;
}

@keyframes bound-anim-img {
  0%,100% {
    top: -29px;
  }
  30% {
    top: -31px;
  }
  50% {
    top: -25px;
  }
  90% {
    top: -25px;
  }
}

@keyframes bound-anim-lower-img {
  0%,100% {
    top: -60px;
  }
  30% {
    top: -62px;
  }
  50% {
    top: -56px;
  }
  90% {
    top: -56px;
  }
}

@keyframes bound-anim-txt {
  0%,100% {
    top: -31px;
  }
  30% {
    top: -33px;
  }
  50% {
    top: -27px;
  }
  90% {
    top: -27px;
  }
}

@keyframes scale-anim {
  0% {
    transform: translate(0px) scale(1.0);
  }
  100% {
    transform: translate(10px) scale(1.3);
  }
}
