.actionButtonDialog {
  display: none;
}

.headingTitle {
  align-self: center;
}

.viewSearchButton {
  margin-left: auto;
}

.flexContainerHeader {
  justify-content: space-between;
}

.flexRowCloseButton {
  display: flex;
  flex-direction: row;
}

.flexButtonMobile {
  display: none;
}

.addressTextField svg:nth-of-type(2) {
  bottom: 11px !important;
}

@media (max-width: 700px) {
  .pathCardContainer {
    height: 400px;
  }

  .pathCardWrapper {
    max-height: 300px;
    overflow-y: scroll !important;
  }

  .flexContainerHeader {
    flex-direction: column;
    margin-top: 10px;
  }

  .headingTitle {
    align-self: flex-start;
    padding-left: 21px;
    font-size: 16px;
  }

  .stationName {
    font-size: 16px;
  }

  .viewSearchButton {
    margin-left: 0;
    margin-right: auto;
  }

  .flexRowCloseButton {
    display: none;
  }

  .flexButtonMobile {
    display: flex;
  }

  .suggestionsActionGroup div {
    width: 320px !important;
    flex-direction: row !important;
  }

  .suggestionsActionGroup button {
    width: 320px !important;
    margin-top: 3px;
    margin-bottom: 3px !important;
    font-size: 14px;
  }

  .suggestionsActionGroup span {
    white-space: break-spaces !important;
    text-align: left !important;
    max-height: 46px;
  }

  .searchButton,
  .routeButtonText {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .searchButton span {
    display: none;
  }
}