.mapPlaceholder {
  position: absolute;
  left: 0;
  z-index: 1001;
}


@media screen and (min-width: 701px) and (max-width: 1200px) {
  .mapPlaceholder {
    position: fixed;
    left: 0;
    z-index: 1001;
    height: 100%;
    width: auto;
  }
}

@media (max-width: 700px) {
  .flexMain {
    flex-direction: column !important;
    overflow: hidden !important;
  }

  .mapPlaceholder {
    position: fixed;
    left: 0;
    z-index: 1001;
    height: 100%;
    width: auto;
  }
}
