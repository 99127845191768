@media (max-width: 700px) {
  .navigationBar {
    width: 100vw !important;
    height: 50px;
    display: flex;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .navigationBar div {
    width: auto !important;
  }

  .navigationBar button {
    height: 100% !important;
    margin-top: 5px !important;
    padding-top: 0px !important;
  }

  .navigationBar svg {
    width: 20px;
  }

  .dividerHorizontalNavigation {
    display: none;
  }
}