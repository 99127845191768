.actionButtonDialog {
    display: none;
}

.titleHighlight {
    color: #184a87;
    font-size: 23px;
    background-color: #FFE600;
    padding: 0 8px;
    border-radius: 2px;
}

.titleHighlightSecondary {
    color: #184a87;
}